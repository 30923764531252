import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { GetIcon } from '@ds/Icons'
import { Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/legacy/colors'
import { ActionButton, ContentBlock } from '@knocklabs/client'
import { RenderItemProps, useKnockFeed } from '@knocklabs/react'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatUTCDate } from 'shared-utils/src/helper/format-date'

import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

type NotificationItemProps = {
	item: RenderItemProps['item']
	setIsOpen: (isOpen: boolean) => void
}

const getBlockContent = (block: ContentBlock | undefined): string => {
	if (!block) return ''
	if (block.type === 'text' || block.type === 'markdown') {
		return block.rendered
	}
	return ''
}

const getBlockActionButtons = (
	block: ContentBlock | undefined,
): ActionButton[] => {
	if (block && block.type === 'button_set') {
		const { buttons } = block
		return buttons.filter((button) => !!button.action && !!button.label)
	}
	return []
}

export const NotificationItem = ({
	item,
	setIsOpen,
}: NotificationItemProps) => {
	const isRead = item.read_at
	const blockContent = getBlockContent(item.blocks[0])
	const blockActionButtons = getBlockActionButtons(item.blocks[1])

	const { feedClient } = useKnockFeed()
	const navigate = useNavigate()
	const contentRef = useRef<HTMLDivElement>(null)

	const handleClickMarkAsRead = () => {
		if (!isRead) {
			feedClient.markAsUnread(item)
		}
	}

	const handleMouseEnterMarkAsRead = () => {
		if (!isRead) {
			setTimeout(() => {
				feedClient.markAsRead(item)
			}, 143)
		}
	}

	const handleNavigate = (href: string | null) => {
		if (!href) return

		if (href.startsWith(window.location.origin)) {
			const relativePath = href.replace(window.location.origin, '')
			navigate(relativePath)
		} else if (href.startsWith('/')) {
			navigate(href)
		} else {
			window.open(href, '_blank')
		}

		setIsOpen(false)
	}

	const handleClickLink = (e: MouseEvent) => {
		e.preventDefault()
		const href = (e.currentTarget as HTMLAnchorElement).getAttribute('href')
		handleNavigate(href)
	}

	useEffect(() => {
		const links = contentRef.current?.getElementsByTagName('a')

		if (!links) return

		Array.from(links).forEach((link) => {
			link.addEventListener('click', handleClickLink)
		})

		return () => {
			Array.from(links).forEach((link) => {
				link.removeEventListener('click', handleClickLink)
			})
		}
	}, [blockContent, navigate])

	return (
		<Flex
			pos="relative"
			px="2px"
			py="3px"
			borderBottom="1px"
			borderColor={COLORS.background[10]}
			onClick={handleClickMarkAsRead}
			onMouseEnter={handleMouseEnterMarkAsRead}
			bg={isRead ? 'transparent' : COLORS.purple[2]}
		>
			<Flex
				pos="absolute"
				top="3px"
				bottom="3px"
				minW="6px"
				bg={isRead ? 'transparent' : '#FFD485'}
				borderRadius="full"
			/>
			<Flex w="100%" h="100%" py={4} pl={4} gap={5}>
				<Box h="100%" w="20px">
					<Flex
						bg={COLORS.background[4]}
						minW={7}
						minH={7}
						maxW={7}
						maxH={7}
						borderRadius="8px"
						justify="center"
						align="center"
					>
						<GetIcon boxSize={5} color="white" icon={Icons.workflow} />
					</Flex>
				</Box>
				<Flex direction="column" gap={2}>
					<Text
						ref={contentRef}
						sx={{
							a: {
								color: COLORS.purple[9],
							},
							blockquote: {
								fontWeight: '400!important',
							},
							'p:has(em)': {
								marginTop: '6px',
							},
							'p:has(strong)': {
								fontWeight: '600!important',
								marginBottom: '4px',
								fontSize: '16px',
								paddingRight: '44px',
							},
							strong: {
								fontWeight: '600!important',
							},
						}}
						dangerouslySetInnerHTML={{
							__html: blockContent,
						}}
						variant={TEXT_ENUM.medium14}
					/>
					{blockActionButtons.length > 0 && (
						<Flex direction="column" gap={2} mt={1}>
							{blockActionButtons.map((button) => (
								<Button
									key={button.name}
									w="fit-content"
									h="28px"
									variant={
										button.name === 'primary'
											? BTN.primary_v2
											: BTN.secondary_v2
									}
									onClick={() => handleNavigate(button.action)}
								>
									{button.label}
								</Button>
							))}
						</Flex>
					)}
					<Text variant={TEXT_ENUM.regular12} color={COLORS.gray[9]} mt={2}>
						{formatUTCDate({
							date: item.inserted_at,
							showTodayYeserday: true,
						})}
					</Text>
				</Flex>
			</Flex>
			<Box
				position="absolute"
				right={0}
				borderRadius="full"
				minW={2}
				minH={2}
				maxH={2}
				maxW={2}
				mr={6}
				mt={6}
				bg={item.read_at ? 'transparent' : COLORS.red[9]}
			/>
		</Flex>
	)
}
