import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/legacy/colors'
import { ColorTokens } from '@ds/tokens/legacy/colors'
import { useNavigate } from 'react-router-dom'

import { TEXT_ENUM } from '@/styles/components/text'

import { SidebarSuboptionType } from '../hooks/useSidebarOptions'

type SuboptionsProps = {
	suboptions?: SidebarSuboptionType[]
	isActive?: boolean
	isExpanded?: boolean
}

export const SidebarNavItemSuboptions = ({
	suboptions,
	isActive,
	isExpanded,
}: SuboptionsProps) => {
	const navigate = useNavigate()

	if (!suboptions?.length || !isActive || !isExpanded) return null

	return (
		<Flex direction="column" pl="22px" gap="4px" mt="4px" cursor="pointer">
			{suboptions.map((suboption) => (
				<Flex
					key={suboption.title}
					h="32px"
					alignItems="center"
					px="12px"
					borderRadius="10px"
					bg={suboption.isActive ? ColorTokens.card_secondary : 'transparent'}
					color={
						suboption.isActive ? COLORS.background[2] : COLORS.background[5]
					}
					onClick={() => navigate(suboption.navTo)}
					_hover={{
						bg: ColorTokens.card_secondary,
					}}
				>
					<Text variant={TEXT_ENUM.medium14} noOfLines={1}>
						{suboption.title}
					</Text>
				</Flex>
			))}
		</Flex>
	)
}
