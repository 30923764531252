import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { IconSwitcher, IconType } from 'ui/src/Icons/IconSwitcher'

interface BadgeProps extends FlexProps {
	backgroundColor: string
	color: string
	leftIcon?: IconType
	rightIcon?: IconType
	text?: string
}

const Badge = ({
	backgroundColor,
	color,
	leftIcon,
	rightIcon,
	text,
	...props
}: BadgeProps): JSX.Element => {
	return (
		<Flex
			h={6}
			px="0.5rem"
			py="0.25rem"
			alignItems="center"
			justifyContent="center"
			gap={0.25}
			backgroundColor={backgroundColor}
			color={color}
			borderRadius="6px"
			{...props}
		>
			{leftIcon && <IconSwitcher icon={leftIcon} />}
			{text && (
				<Text fontSize="12px" fontWeight={500}>
					{text}
				</Text>
			)}
			{rightIcon && <IconSwitcher icon={rightIcon} />}
		</Flex>
	)
}

export default Badge
