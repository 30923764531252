import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/legacy/colors'

import { SHADOWS } from '../constants'

export enum MenuVariant {
	dots = 'dots',
}

const helpers = createMultiStyleConfigHelpers(menuAnatomy.keys)

export const menuTheme = helpers.defineMultiStyleConfig({
	baseStyle: () => ({
		button: {
			bg: ColorTokens.white,
			borderWidth: '1px',
			fontWeight: 400,
			border: ColorTokens.accent,
			borderRadius: '6px',
			px: '4px',
			py: '4px',
			fontSize: 'sm',
			_hover: {
				background: ColorTokens.bg_hover,
			},
			_active: {
				background: ColorTokens.bg_hover,
				boxShadow: 'none',
			},
		},
		list: {
			bg: ColorTokens.white,
			border: 'none',
			p: 1,
			overflow: 'auto',
			borderRadius: '8px',
			boxShadow: SHADOWS.popover,
		},
		item: {
			pl: 2,
			py: 2,
			borderRadius: '8px',
			fontSize: 'sm',
			fontWeight: 500,
			_hover: {
				background: ColorTokens.bg_hover,
			},
			_active: {
				background: ColorTokens.bg_hover,
			},
			_focus: {
				background: ColorTokens.bg_hover,
			},
			_expanded: {
				background: ColorTokens.bg_hover,
			},
		},
	}),

	variants: {
		[MenuVariant.dots]: {
			button: {
				border: 'none',
				borderRadius: '6px',
				outline: 'none',
				p: '4px',
				w: '32px',
				h: '32px',
				maxH: '32px',
				color: ColorTokens.icon_primary,
				_hover: {
					bg: ColorTokens.bg_hover,
				},
			},
		},
	},
})
