import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Edge, Node } from 'reactflow'

interface LogicJumpsState {
	selectedNode: Node | null
	selectedEdge: Edge | null
	selectedPageId: string | null
}

const initialState: LogicJumpsState = {
	selectedNode: null,
	selectedEdge: null,
	selectedPageId: null,
}

export const logicJumpsSlice = createSlice({
	name: 'logicJumps',
	initialState,
	reducers: {
		setSelectedNode: (state, { payload }: PayloadAction<Node | null>) => {
			state.selectedNode = payload
		},
		setSelectedEdge: (state, { payload }: PayloadAction<Edge | null>) => {
			state.selectedEdge = payload
		},
		setSelectedPageId: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedPageId = payload
		},
	},
})

export const logicJumpsActions = logicJumpsSlice.actions
export const logicJumpsReducer = logicJumpsSlice.reducer
