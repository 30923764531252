import { Avatar, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/legacy/colors'
import { Link } from 'react-router-dom'
import { getFullName } from 'shared-utils'

import { useMember } from '@/app/user/api/members/selector'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	isExpanded: boolean
}

export const SidebarProfile = ({ isExpanded }: Props) => {
	const member = useMember()?.member

	const fullName = getFullName({
		firstName: member?.first_name,
		lastName: member?.last_name,
	})

	return (
		<Link to="settings/user/profile">
			<Flex
				w="full"
				gap="12px"
				px={2}
				justifyContent={isExpanded ? 'flex-start' : 'center'}
				alignItems="center"
			>
				<Avatar
					w="32px"
					h="32px"
					src={member?.avatar_url || undefined}
					name={fullName || ''}
					borderRadius="full"
				/>
				{isExpanded && (
					<Flex flexDir="column">
						<Text
							variant={TEXT_ENUM.medium14}
							noOfLines={1}
							overflow="hidden"
							textOverflow="clip"
						>
							{fullName || '-'}
						</Text>
						<Text
							variant={TEXT_ENUM.regular12}
							color={COLORS.gray[9]}
							noOfLines={1}
							overflow="hidden"
							textOverflow="clip"
						>
							{member?.email ?? ''}
						</Text>
					</Flex>
				)}
			</Flex>
		</Link>
	)
}
