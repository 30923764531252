import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'
import { setToken } from '@/utils/helpers/token'

import {
	JoinTeamPayload,
	JoinTeamReturnPayload,
	MemberType,
	ResetPasswordPayload,
	TeamMember,
	UpdateMemberPayload,
	UpdateMembersPayload,
	UpdateOnboardingStepPayload,
} from './type'

export enum MemberEndPoints {
	/** Query */
	getMember = 'getMember',
	getTeamMembers = 'getTeamMembers',

	/** Mutation */
	updateMember = 'updateMember',
	updateMembers = 'updateMembers',
	joinTeam = 'joinTeam',
	registerWithEmail = 'registerWithEmail',
	loginWithEmail = 'loginWithEmail',
	logout = 'logout',
	updateMemberJoinTeam = 'updateMemberJoinTeam',
	resetMemberPassword = 'resetMemberPassword',
	updateOnboardingStep = 'updateOnboardingStep',
}

const membersApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[MemberEndPoints.getMember]: builder.query<
			MemberType & { knockToken: string },
			void
		>({
			query: () => ({
				url: `/auth`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_MEMBER'],
		}),

		[MemberEndPoints.getTeamMembers]: builder.query<
			TeamMember[],
			number | null | undefined
		>({
			query: (teamId) => ({
				url: `/members/team/${teamId}`,
				responseHandler: responseTransformer,
			}),
		}),

		[MemberEndPoints.updateMember]: builder.mutation<
			MemberType,
			UpdateMemberPayload
		>({
			query: (payload) => ({
				url: `/members/member/${payload.member.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_MEMBER'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchMember = dispatch(
					membersApi.util.updateQueryData(
						MemberEndPoints.getMember,
						undefined,
						(draft) => {
							// should always be draft.id === arg.member.id
							return {
								...draft,
								...arg.member,
							}
						},
					),
				)

				const patchTeamMembers = dispatch(
					membersApi.util.updateQueryData(
						MemberEndPoints.getTeamMembers,
						arg.member.team_id ?? patchMember.patches[0].value?.team_id,
						(draft) => {
							const index = draft?.findIndex(
								(member) => member.id === arg.member.id,
							)

							if (index !== undefined && index !== -1) {
								draft[index] = {
									...draft[index],
									...arg.member,
								}
							}
						},
					),
				)
				try {
					await queryFulfilled
				} catch {
					patchMember.undo()
					patchTeamMembers.undo()
				}
			},
		}),

		[MemberEndPoints.updateMembers]: builder.mutation<
			TeamMember[],
			UpdateMembersPayload
		>({
			query: (payload) => ({
				url: `/members`,
				method: 'PATCH',
				body: { members: payload.members },
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_MEMBERS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTeamMembers = dispatch(
					membersApi.util.updateQueryData(
						MemberEndPoints.getTeamMembers,
						arg.team_id,
						(draft) => {
							draft.forEach((member, index) => {
								const updatedMember = arg.members.find(
									(m) => m.id === member.id,
								)

								if (updatedMember) {
									if (updatedMember.team_id === null) {
										draft.splice(index, 1)
									} else {
										draft[index] = {
											...draft[index],
											...updatedMember,
										}
									}
								}
							})

							return draft
						},
					),
				)
				try {
					await queryFulfilled
				} catch {
					patchTeamMembers.undo()
				}
			},
		}),

		[MemberEndPoints.joinTeam]: builder.mutation<MemberType, JoinTeamPayload>({
			query: (payload) => ({
				url: `/members/member/${payload.member.id}`,
				method: 'PUT',
				body: {
					teamId: payload.member.team_id,
				},
			}),
			invalidatesTags: ['GET_MEMBER'],
			transformResponse: (response: { data: JoinTeamReturnPayload }) => {
				const token = response?.data?.accessToken
				if (token) {
					setToken(token)
				}
				return response.data.member
			},
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled
					dispatch(
						membersApi.util.updateQueryData(
							MemberEndPoints.getMember,
							undefined,
							(draft) => {
								return {
									...draft,
									...data,
								}
							},
						),
					)
				} catch (err) {
					console.error(err)
				}
			},
		}),
		[MemberEndPoints.updateOnboardingStep]: builder.mutation<
			any,
			UpdateOnboardingStepPayload
		>({
			query: (payload) => ({
				url: `/auth/onboarding$step=${payload.step}`,
			}),
		}),
		[MemberEndPoints.logout]: builder.mutation<any, void>({
			query: () => ({
				url: '/auth/logout',
			}),
		}),
		[MemberEndPoints.resetMemberPassword]: builder.mutation<
			any,
			ResetPasswordPayload
		>({
			query: (payload) => ({
				url: '/auth/reset',
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['GET_MEMBER'],
		}),
	}),
})

export const {
	useGetMemberQuery,
	useUpdateMemberMutation,
	useGetTeamMembersQuery,
	useUpdateMembersMutation,
	useLazyGetTeamMembersQuery,
	useLogoutMutation,
	useUpdateOnboardingStepMutation,
	useJoinTeamMutation,
	useResetMemberPasswordMutation,
} = membersApi

export { membersApi }
