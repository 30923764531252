import { PageLayout, ThemeStyle } from './types/theme'

export const defaultFormStyle: Required<ThemeStyle> = {
	favicon: {
		image: null,
		enabled: true,
	},
	primaryFont: 'Inter',
	customPrimaryFontUrl: null,
	secondaryFont: 'Inter',
	customSecondaryFontUrl: null,
	logo: {
		image: null,
		side: 'media',
		enabled: true,
		url: null,
	},
	logoAreaBackground: '#FFFFFF',
	logoRedirectLink: null,
	title: '',
	subtitle: '',
	endTitle: 'Thanks for filling out the form!',
	endSubtitle: "We'll get back to you shortly.",
	layout: PageLayout.no_media,
	alignment: 'start',
	primaryMediaAreaColor: '#FFFFFF',
	primaryFormAreaColor: '#000000',
	secondaryMediaAreaColor: '#FAFAFA',
	secondaryFormAreaColor: '#111111',
	mediaAreaBackgroundType: 'color',
	mediaAreaBackgroundColor: '#000000',
	mediaAreaBackgroundImage: '',
	mediaAreaBackgroundImageOverlay: 0,
	mediaAreaBackgroundImageBlur: 0,
	formAreaBackgroundType: 'color',
	formAreaBackgroundColor: '#FFFFFF',
	formAreaBackgroundImage: '',
	formAreaBackgroundImageOverlay: 0,
	formAreaBackgroundImageBlur: 0,
	borderRadius: 'full',
	primaryButtonsBackground: '#000000',
	primaryButtonsBorderColor: '#000000',
	primaryButtonsColor: '#FFFFFF',
	secondaryButtonsBackground: '#FFFFFF',
	secondaryButtonsBorderColor: '#FFFFFF',
	secondaryButtonsColor: '#000000',
	phoneCountryCode: '1',
	requireWorkEmail: false,
	nestFormArea: false,
	nestedFormAreaPadding: null,
	nestedFormAreaBorderRadius: null,
	globalBackgroundType: 'color',
	globalBackgroundColor: '',
	globalBackgroundImage: '',
	globalBackgroundImageOverlay: 0,
	globalBackgroundImageBlur: 0,
	variant: null,
	fieldVerticalSpacing: '',
	fieldBorderRadius: 'md',
	fieldBackground: '',
	defaultState: null,
	hoverState: null,
	selectedState: null,
	activeState: null,
	fieldDimensions: {
		width: 'auto',
		height: '',
		unit: 'px',
	},
	buttonIcon: true,
	buttonIconUrl: null,
	buttonAlignment: 'center',
	buttonWidth: '100',
	titleSpacing: '40px',
}
