import { ComponentStyleConfig } from '@chakra-ui/react'

import { BorderTokens, RADII } from '../../../tokens/legacy/borders'
import { BtnVariants } from '../../../tokens/legacy/button'
import { COLORS, ColorTokens } from '../../../tokens/legacy/colors'
import { SHADOWS } from '../../../tokens/legacy/shadows'
import { SPACING } from '../../../tokens/legacy/space'
import { FONT_SIZES, WEIGHT } from '../../../tokens/legacy/typography'

const Button: ComponentStyleConfig = {
	baseStyle: {
		bg: 'transparent',
		_focus: {
			boxShadow: 'none',
		},
	},
	variants: {
		[BtnVariants.primary]: {
			bg: COLORS.purple[9],
			color: ColorTokens.white,
			borderRadius: RADII.md,

			fontWeight: WEIGHT.semibold,
			fontSize: FONT_SIZES.sm,
			height: SPACING[10],
			lineHeight: SPACING[5],
			border: '1px solid',
			borderColor: 'transparent',

			_hover: {
				background: COLORS.purple[10],
				_disabled: {
					background: COLORS.purple[9],
				},
			},
			_active: { background: COLORS.purple[11] },
			_focusVisible: {
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},
			_disabled: {
				background: COLORS.purple[9],
				opacity: 0.5,
			},
		},

		[BtnVariants.secondary]: {
			bg: ColorTokens.white,
			color: COLORS.gray[12],
			borderRadius: RADII.md,
			fontWeight: WEIGHT.semibold,
			fontSize: FONT_SIZES.sm,
			height: SPACING[10],
			lineHeight: SPACING[5],
			boxShadow: SHADOWS.depth0,
			border: BorderTokens.secondary_btn,
			_hover: {
				borderColor: COLORS.background[9],
				_disabled: {
					border: BorderTokens.secondary_btn,
				},
			},
			_active: { background: COLORS.background[12] },
			_focusVisible: {
				borderColor: COLORS.background[10],
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_disabled: {
				opacity: 0.5,
			},
		},

		[BtnVariants.outline]: {
			bg: ColorTokens.white,
			color: COLORS.purple[9],
			borderRadius: RADII.md,

			fontWeight: WEIGHT.semibold,
			fontSize: FONT_SIZES.sm,
			minHeight: 10,
			height: 10,
			// lineHeight: SPACING[5],
			border: '1px solid',
			borderColor: COLORS.purple[9],

			_hover: {
				color: COLORS.purple[10],
				background: COLORS.purple[3],
				borderColor: COLORS.purple[10],

				_disabled: {
					background: ColorTokens.white,
					borderColor: COLORS.purple[9],
				},
			},

			_active: {
				color: COLORS.purple[10],
				background: COLORS.purple[4],
				borderColor: COLORS.purple[11],
			},

			_focusVisible: {
				color: COLORS.purple[9],
				background: COLORS.purple[3],
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_disabled: {
				background: ColorTokens.white,
				borderColor: COLORS.purple[9],
				opacity: 0.5,
			},
		},

		[BtnVariants.default]: () => ({
			bg: ColorTokens.white,
			color: COLORS.gray[12],
			boxShadow: 'medium',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			height: 10,
		}),

		[BtnVariants.link]: () => ({
			bg: 'transparent',
			fontSize: 'xs',
			mt: '2px',
			color: ColorTokens.text_secondary,
			_hover: {
				textDecoration: 'none',
				color: '#222B48',
			},
			_active: {
				color: '#222B48',
			},
		}),
		[BtnVariants.tertiary]: () => ({
			bg: 'rgba(0,0,0,0)',
			color: COLORS.gray[10],
			fontSize: 'sm',
			height: 9,
			borderWidth: '1px',
			borderColor: 'transparent',
			_hover: {
				background: COLORS.background[3],
			},
			_active: {
				background: COLORS.background[3],
				color: ColorTokens.text,
			},
		}),
		[BtnVariants.success]: {
			bg: ColorTokens.success,
			color: ColorTokens.white,
			boxShadow: 'medium',
			fontWeight: 600,
			fontSize: 'sm',
			height: 9,
			_hover: { background: COLORS.green[10] },
			_active: { background: COLORS.green[11] },
		},
		[BtnVariants.integrations]: () => ({
			bg: ColorTokens.white,
			boxShadow: 'medium',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: ColorTokens.border_primary,
			fontSize: '13px',
			height: 8,
		}),

		[BtnVariants.ghost]: () => ({
			background: 'rgba(255,255,255,0)',
			color: '#AAADB6',
			letterSpacing: '0.2px',
			fontSize: 'sm',
			fontWeight: 500,
			_hover: {
				background: 'rgba(37,17,17,0.06)',
				color: '#021030',
			},
		}),

		[BtnVariants.form]: {
			padding: '8px 16px',
			borderRadius: 'md',
			border: 'none',
			width: 'fit-content',
			height: '34px',

			fontSize: 'sm',
			fontWeight: 600,
		},

		[BtnVariants.active_tab]: () => ({
			bg: 'rgba(0,0,0,0)',
			borderRadius: 0,
			fontWeight: 600,
			fontSize: '14px',
			borderBottomWidth: '1px',
			borderColor: ColorTokens.text,
			color: ColorTokens.text,
			mx: 0,
			px: 0,
			letterSpacing: '0.1px',
			_active: { background: 'rgba(0,0,0,0)' },
			_hover: {
				background: 'rgba(0,0,0,0)',
				color: ColorTokens.text,
			},
		}),
		[BtnVariants.inactive_tab]: {
			bg: 'rgba(0,0,0,0)',
			borderRadius: 0,
			fontWeight: 600,
			fontSize: '14px',
			borderBottomWidth: '1px',
			borderColor: 'rgba(0,0,0,0)',
			color: ColorTokens.text_secondary,
			mx: 0,
			px: 0,
			letterSpacing: '0.1px',
			_active: { background: 'rgba(0,0,0,0)' },
			_hover: {
				background: 'rgba(0,0,0,0)',
				color: ColorTokens.text_secondary,
			},
		},
		[BtnVariants.danger_primary]: () => ({
			bg: ColorTokens.white,
			boxShadow: '0px 1px 3px rgba(12, 17, 61, 0.08)',
			borderWidth: '1px',
			fontWeight: 600,
			height: 9,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			_hover: {
				background: 'rgba(209, 40, 71,0.18)',
				color: '#fc4768',
				borderColor: '#fc4768',
			},
		}),
		[BtnVariants.danger_secondary]: () => ({
			bg: '#EDEDED',
			color: '#8f8f8f',
			fontSize: 'sm',
			letterSpacing: '0.2px',
			borderWidth: '1px',
			borderColor: '#EDEDED',
			_hover: {
				background: '#FFE2E8',
				color: '#F32451',
				borderColor: '#F32451',
			},
		}),
		[BtnVariants.danger_tertiary]: () => ({
			bg: ColorTokens.white,
			boxShadow: '0px 1px 3px rgba(12, 17, 61, 0.08)',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			_hover: {
				background: '#FFEBEF',
				color: '#F32451',
				borderColor: '#F32451',
			},
			_active: {
				background: '#FFC4D1',
				color: '#F32451',
				borderColor: '#F32451',
			},
		}),
		[BtnVariants.secondary_active_tab]: () => ({
			bg: 'rgba(99, 99, 242, 0.15)',
			color: ColorTokens.text_primary,
			borderRadius: RADII.md,
			border: '1px solid #8E8EF8',
			fontWeight: 600,
			fontSize: 'sm',
			height: 9,
			_hover: { background: 'rgba(99, 99, 242, 0.2)' },
			_active: { background: 'rgba(99, 99, 242, 0.2)' },
		}),
		[BtnVariants.secondary_inactive_tab]: {
			bg: ColorTokens.bg,
			fontWeight: 600,
			border: BorderTokens.primary,
			borderRadius: RADII.sm,
			fontSize: 'sm',
			height: 9,
			color: ColorTokens.text_primary,
		},
		[BtnVariants.outlined_secondary]: {
			bg: ColorTokens.white,
			color: COLORS.purple[9],
			borderRadius: RADII.md,

			fontWeight: WEIGHT.semibold,
			fontSize: FONT_SIZES.sm,
			minHeight: 10,
			height: 10,
			// lineHeight: SPACING[5],
			border: '1px solid',
			borderColor: COLORS.purple[9],

			_hover: {
				color: COLORS.purple[10],
				background: COLORS.purple[3],
				borderColor: COLORS.purple[10],

				_disabled: {
					background: ColorTokens.white,
					borderColor: COLORS.purple[9],
				},
			},

			_active: {
				color: COLORS.purple[10],
				background: COLORS.purple[4],
				borderColor: COLORS.purple[11],
			},

			_focusVisible: {
				color: COLORS.purple[9],
				background: COLORS.purple[3],
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_disabled: {
				background: ColorTokens.white,
				borderColor: COLORS.purple[9],
				opacity: 0.5,
			},
		},
	},
}

export default Button
