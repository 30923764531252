import type { ComponentStyleConfig } from '@chakra-ui/react'

import { ColorTokens } from '../../../tokens/legacy/colors'
import { SHADOWS } from '../../../tokens/legacy/shadows'

const Menu: ComponentStyleConfig = {
	baseStyle: () => ({
		button: {
			bg: ColorTokens.white,
			borderWidth: '1px',
			fontWeight: 400,
			border: ColorTokens.accent,
			borderRadius: '6px',
			px: '8px',
			py: '8px',
			fontSize: 'sm',
			_hover: {
				background: ColorTokens.bg_hover,
			},
			_active: {
				background: ColorTokens.bg_hover,
				boxShadow: SHADOWS.depth1,
			},
			rightIcon: {},
		},
		list: {
			bg: ColorTokens.white,
			border: 'none',
			p: 1,
			overflow: 'auto',
			borderRadius: 12,
			boxShadow: SHADOWS.depth1,
		},
		item: {
			pl: 4,
			py: 2,
			borderRadius: '8px',
			fontSize: 'sm',
			fontWeight: 500,
			_hover: {
				background: ColorTokens.bg_hover,
			},
			_active: {
				background: ColorTokens.bg_active,
			},
			_focus: {
				background: ColorTokens.bg_hover,
			},
		},
	}),
	variants: {},
}

export default Menu
