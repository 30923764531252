import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

import { ReactComponent as _CancelCalendar } from '../assets/icons/_cancel_calendar.svg'
import { ReactComponent as _CheckCircle } from '../assets/icons/_check-circle.svg'
import { ReactComponent as AddUser } from '../assets/icons/add-user.svg'
import { ReactComponent as Adjustments } from '../assets/icons/adjustments.svg'
import { ReactComponent as Alert } from '../assets/icons/alert.svg'
import { ReactComponent as AlertTriangle } from '../assets/icons/alert-triangle.svg'
import { ReactComponent as AlignHCenter } from '../assets/icons/align-horizontal-center.svg'
import { ReactComponent as AlignHLeft } from '../assets/icons/align-left.svg'
import { ReactComponent as AlignHRight } from '../assets/icons/align-right.svg'
import { ReactComponent as Archive } from '../assets/icons/archive.svg'
import { ReactComponent as ARR } from '../assets/icons/arr.svg'
import { ReactComponent as IfElse } from '../assets/icons/arrow-decision-left.svg'
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowMore } from '../assets/icons/arrow-more.svg'
import { ReactComponent as ArrowSquare } from '../assets/icons/arrow-square.svg'
import { ReactComponent as ArrowUp } from '../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowUpRight } from '../assets/icons/arrow-up-right.svg'
import { ReactComponent as ArrowsClockwise } from '../assets/icons/arrows-clockwise.svg'
import { ReactComponent as Asc } from '../assets/icons/asc.svg'
import { ReactComponent as Asterisk } from '../assets/icons/asterisk.svg'
import { ReactComponent as At } from '../assets/icons/at.svg'
import { ReactComponent as Bell } from '../assets/icons/bell.svg'
import { ReactComponent as Board } from '../assets/icons/board.svg'
import { ReactComponent as Bold } from '../assets/icons/bold.svg'
import { ReactComponent as Books } from '../assets/icons/books.svg'
import { ReactComponent as BorderRadiusSide } from '../assets/icons/border-radius-side.svg'
import { ReactComponent as BoxPlus } from '../assets/icons/box-plus.svg'
import { ReactComponent as Branch } from '../assets/icons/branch.svg'
import { ReactComponent as BrowserCode } from '../assets/icons/browser-code.svg'
import { ReactComponent as BrowserPage } from '../assets/icons/browser-page.svg'
import { ReactComponent as BulletList } from '../assets/icons/bullet-list.svg'
import { ReactComponent as BulletList2 } from '../assets/icons/bullet-list-2.svg'
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg'
import { ReactComponent as CalendarCheck } from '../assets/icons/calendar-check.svg'
import { ReactComponent as CalendarClock } from '../assets/icons/calendar-clock.svg'
import { ReactComponent as CalendarEdit } from '../assets/icons/calendar-edit.svg'
import { ReactComponent as CalendarEmpty } from '../assets/icons/calendar-empty.svg'
import { ReactComponent as CalendarPeople } from '../assets/icons/calendar-people.svg'
import { ReactComponent as CalendarSearch } from '../assets/icons/calendar-search.svg'
import { ReactComponent as CalendarSmile } from '../assets/icons/calendar-smile.svg'
import { ReactComponent as CalendarWeeks } from '../assets/icons/calendar-weeks.svg'
import { ReactComponent as CalendarWorld } from '../assets/icons/calendar-world.svg'
import { ReactComponent as Calibration } from '../assets/icons/calibration.svg'
import { ReactComponent as CameraPlus } from '../assets/icons/camera-plus.svg'
import { ReactComponent as CardView } from '../assets/icons/card-view.svg'
import { ReactComponent as Check } from '../assets/icons/check.svg'
import { ReactComponent as CheckCircle } from '../assets/icons/check-circle.svg'
import { ReactComponent as Checklist } from '../assets/icons/checklist.svg'
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '../assets/icons/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronPairLeft } from '../assets/icons/chevron-pair-left.svg'
import { ReactComponent as ChevronPairRight } from '../assets/icons/chevron-pair-right.svg'
import { ReactComponent as ChevronRight } from '../assets/icons/chevron-right.svg'
import { ReactComponent as Clock } from '../assets/icons/clock.svg'
import { ReactComponent as ClockEdit } from '../assets/icons/clock-edit.svg'
import { ReactComponent as ClockMusic } from '../assets/icons/clock-music.svg'
import { ReactComponent as Close } from '../assets/icons/close.svg'
import { ReactComponent as CollapseArrow } from '../assets/icons/collapse-arrow.svg'
import { ReactComponent as Command } from '../assets/icons/command.svg'
import { ReactComponent as CompanyAttributes } from '../assets/icons/company-attributes.svg'
import { ReactComponent as Copy } from '../assets/icons/copy.svg'
import { ReactComponent as CreatedVersion } from '../assets/icons/created-version.svg'
import { ReactComponent as Crown } from '../assets/icons/crown.svg'
import { ReactComponent as CursorClick } from '../assets/icons/cursor-click.svg'
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg'
import { ReactComponent as Data } from '../assets/icons/data.svg'
import { ReactComponent as DefaultLogo } from '../assets/icons/default-logo.svg'
import { ReactComponent as Desc } from '../assets/icons/desc.svg'
import { ReactComponent as Design } from '../assets/icons/design.svg'
import { ReactComponent as Details } from '../assets/icons/details.svg'
import { ReactComponent as Document } from '../assets/icons/document.svg'
import { ReactComponent as DotsHorizontal } from '../assets/icons/dots-horizontal.svg'
import { ReactComponent as DotsVertical } from '../assets/icons/dots-vertical.svg'
import { ReactComponent as Download } from '../assets/icons/download.svg'
import { ReactComponent as Drag } from '../assets/icons/drag.svg'
import { ReactComponent as DragHand } from '../assets/icons/drag-hand.svg'
import { ReactComponent as DragHandle } from '../assets/icons/drag-handle.svg'
import { ReactComponent as DragHandleHorizontal } from '../assets/icons/drag-handle-horizontal.svg'
import { ReactComponent as Dropper } from '../assets/icons/dropper.svg'
import { ReactComponent as Edit } from '../assets/icons/edit.svg'
import { ReactComponent as Edit2 } from '../assets/icons/edit-2.svg'
import { ReactComponent as EditPen } from '../assets/icons/edit-pen.svg'
import { ReactComponent as Enrichment } from '../assets/icons/enrichment.svg'
import { ReactComponent as Enter } from '../assets/icons/enter.svg'
import { ReactComponent as ExclamationMark } from '../assets/icons/exclamation-mark.svg'
import { ReactComponent as Expand } from '../assets/icons/expand.svg'
import { ReactComponent as ExpandArrow } from '../assets/icons/expand-arrow.svg'
import { ReactComponent as Eye } from '../assets/icons/eye.svg'
import { ReactComponent as EyeOff } from '../assets/icons/eye-off.svg'
import { ReactComponent as Eyedropper } from '../assets/icons/eyedropper.svg'
import { ReactComponent as FileTypo } from '../assets/icons/file-typo.svg'
import { ReactComponent as Filter } from '../assets/icons/filter.svg'
import { ReactComponent as FilterFunnel } from '../assets/icons/filter-funnel.svg'
import { ReactComponent as Flag } from '../assets/icons/flag.svg'
import { ReactComponent as Folder } from '../assets/icons/folder.svg'
import { ReactComponent as FolderOff } from '../assets/icons/folder-off.svg'
import { ReactComponent as Font } from '../assets/icons/font.svg'
import { ReactComponent as FontSize } from '../assets/icons/font-size.svg'
import { ReactComponent as FormNumbers } from '../assets/icons/form-numbers.svg'
import { ReactComponent as FormQuestions } from '../assets/icons/form-questions.svg'
import { ReactComponent as FormSubmissionTrigger } from '../assets/icons/form-submission-trigger.svg'
import { ReactComponent as FormTextarea } from '../assets/icons/form-textarea.svg'
import { ReactComponent as Forms } from '../assets/icons/forms.svg'
import { ReactComponent as Frame } from '../assets/icons/frame.svg'
import { ReactComponent as FullScreenSize } from '../assets/icons/full-screen.svg'
import { ReactComponent as Funding } from '../assets/icons/funding.svg'
import { ReactComponent as GitMerge } from '../assets/icons/git_merge.svg'
import { ReactComponent as GitBranch } from '../assets/icons/git-branch.svg'
import { ReactComponent as GitFork } from '../assets/icons/git-fork.svg'
import { ReactComponent as GoBack } from '../assets/icons/go-back.svg'
import { ReactComponent as GoForward } from '../assets/icons/go-forward.svg'
import { ReactComponent as Grid } from '../assets/icons/grid.svg'
import { ReactComponent as Group } from '../assets/icons/group.svg'
import { ReactComponent as Guide } from '../assets/icons/guide.svg'
import { ReactComponent as HashTag } from '../assets/icons/hashtag.svg'
import { ReactComponent as Image } from '../assets/icons/image.svg'
import { ReactComponent as ImportForm } from '../assets/icons/import-form.svg'
import { ReactComponent as ImportedForm } from '../assets/icons/imported-form.svg'
import { ReactComponent as Industry } from '../assets/icons/industry.svg'
import { ReactComponent as Info } from '../assets/icons/info.svg'
import { ReactComponent as Input } from '../assets/icons/input.svg'
import { ReactComponent as Italics } from '../assets/icons/italics.svg'
import { ReactComponent as Leads } from '../assets/icons/leads.svg'
import { ReactComponent as LeftSide } from '../assets/icons/left-side.svg'
import { ReactComponent as Lightbulb } from '../assets/icons/lightbulb.svg'
import { ReactComponent as Link } from '../assets/icons/link.svg'
import { ReactComponent as Link2 } from '../assets/icons/link-2.svg'
import { ReactComponent as LinkForm } from '../assets/icons/link-form.svg'
import { ReactComponent as LinkOff } from '../assets/icons/link-off.svg'
import { ReactComponent as List } from '../assets/icons/list.svg'
import { ReactComponent as ListPlus } from '../assets/icons/list-plus.svg'
import { ReactComponent as Location } from '../assets/icons/location.svg'
import { ReactComponent as LocationPin } from '../assets/icons/location-pin.svg'
import { ReactComponent as LocationServices } from '../assets/icons/location-services.svg'
import { ReactComponent as Lock } from '../assets/icons/lock.svg'
import { ReactComponent as LongText } from '../assets/icons/long-text.svg'
import { ReactComponent as MagicFix } from '../assets/icons/magic-fix.svg'
import { ReactComponent as Mail } from '../assets/icons/mail.svg'
import { ReactComponent as MailCode } from '../assets/icons/mail-code.svg'
import { ReactComponent as MailDelete } from '../assets/icons/mail-delete.svg'
import { ReactComponent as MapMarker } from '../assets/icons/map-marker-location.svg'
import { ReactComponent as MapSimpleMarker } from '../assets/icons/map-simple-marker.svg'
import { ReactComponent as Mapping } from '../assets/icons/mapping.svg'
import { ReactComponent as Meetings } from '../assets/icons/meetings.svg'
import { ReactComponent as Microphone } from '../assets/icons/microphone.svg'
import { ReactComponent as Minus } from '../assets/icons/minus.svg'
import { ReactComponent as MobileSize } from '../assets/icons/mobile-size.svg'
import { ReactComponent as ModalSize } from '../assets/icons/modal-size.svg'
import { ReactComponent as More } from '../assets/icons/more.svg'
import { ReactComponent as MultSelect } from '../assets/icons/multi-select.svg'
import { ReactComponent as NewForm } from '../assets/icons/new-form.svg'
import { ReactComponent as NoLeads } from '../assets/icons/no-leads.svg'
import { ReactComponent as Notes } from '../assets/icons/notes.svg'
import { ReactComponent as NumberedList } from '../assets/icons/numbered-list.svg'
import { ReactComponent as Open } from '../assets/icons/open.svg'
import { ReactComponent as OpenLink } from '../assets/icons/open-link.svg'
import { ReactComponent as PaintBrushAngled } from '../assets/icons/paint-brush-angled.svg'
import { ReactComponent as Palette } from '../assets/icons/palette.svg'
import { ReactComponent as PaperFold } from '../assets/icons/paper-fold.svg'
import { ReactComponent as Parameters } from '../assets/icons/parameters.svg'
import { ReactComponent as Pause } from '../assets/icons/pause.svg'
import { ReactComponent as Person } from '../assets/icons/person.svg'
import { ReactComponent as PersonBadge } from '../assets/icons/person-badge.svg'
import { ReactComponent as PersonId } from '../assets/icons/person-id.svg'
import { ReactComponent as Phone } from '../assets/icons/phone.svg'
import { ReactComponent as Picture } from '../assets/icons/picture.svg'
import { ReactComponent as Pin } from '../assets/icons/pin.svg'
import { ReactComponent as Plane } from '../assets/icons/plane.svg'
import { ReactComponent as Play } from '../assets/icons/play.svg'
import { ReactComponent as PlayBox } from '../assets/icons/play-box.svg'
import { ReactComponent as Plug } from '../assets/icons/plug.svg'
import { ReactComponent as Plus } from '../assets/icons/plus.svg'
import { ReactComponent as PublishedVersion } from '../assets/icons/published-version.svg'
import { ReactComponent as QueuesArrows } from '../assets/icons/queues-arrows.svg'
import { ReactComponent as Quotes } from '../assets/icons/quotes.svg'
import { ReactComponent as Refresh } from '../assets/icons/refresh.svg'
import { ReactComponent as Restricted } from '../assets/icons/restricted.svg'
import { ReactComponent as RightSide } from '../assets/icons/right-side.svg'
import { ReactComponent as Rocket } from '../assets/icons/rocket.svg'
import { ReactComponent as Role } from '../assets/icons/role.svg'
import { ReactComponent as Route } from '../assets/icons/route.svg'
import { ReactComponent as Route2 } from '../assets/icons/route-2.svg'
import { ReactComponent as RouteRight } from '../assets/icons/route-right.svg'
import { ReactComponent as Search } from '../assets/icons/search.svg'
import { ReactComponent as Send } from '../assets/icons/send.svg'
import { ReactComponent as SendMail } from '../assets/icons/send-mail.svg'
import { ReactComponent as Settings } from '../assets/icons/settings.svg'
import { ReactComponent as Share } from '../assets/icons/share.svg'
import { ReactComponent as Share2 } from '../assets/icons/share2.svg'
import { ReactComponent as Shine } from '../assets/icons/shine.svg'
import { ReactComponent as ShortText } from '../assets/icons/short-text.svg'
import { ReactComponent as Sidebar } from '../assets/icons/sidebar.svg'
import { ReactComponent as SingleSelect } from '../assets/icons/single-select.svg'
import { ReactComponent as SleepMoon } from '../assets/icons/sleep-moon.svg'
import { ReactComponent as Sorting } from '../assets/icons/sorting.svg'
import { ReactComponent as Sorting2 } from '../assets/icons/sorting-2.svg'
import { ReactComponent as Sparkles } from '../assets/icons/sparkles.svg'
import { ReactComponent as Sparkles2 } from '../assets/icons/sparkles2.svg'
import { ReactComponent as Sparkles3 } from '../assets/icons/sparkles3.svg'
import { ReactComponent as Speaker } from '../assets/icons/speaker.svg'
import { ReactComponent as StrikedText } from '../assets/icons/striked-text.svg'
import { ReactComponent as Sun } from '../assets/icons/sun.svg'
import { ReactComponent as SwitchArrow } from '../assets/icons/switch-arrows.svg'
import { ReactComponent as Table } from '../assets/icons/table.svg'
import { ReactComponent as Tag } from '../assets/icons/tag.svg'
import { ReactComponent as TestTube } from '../assets/icons/test-tube.svg'
import { ReactComponent as Text } from '../assets/icons/text.svg'
import { ReactComponent as Text2 } from '../assets/icons/text-2.svg'
import { ReactComponent as TextboxSave } from '../assets/icons/textbox-save.svg'
import { ReactComponent as TextboxUpload } from '../assets/icons/textbox-upload.svg'
import { ReactComponent as TimeReload } from '../assets/icons/time-reload.svg'
import { ReactComponent as Timer } from '../assets/icons/timer.svg'
import { ReactComponent as Toggle } from '../assets/icons/toggle.svg'
import { ReactComponent as Trash } from '../assets/icons/trash.svg'
import { ReactComponent as Tree } from '../assets/icons/tree.svg'
import { ReactComponent as TriggerBy } from '../assets/icons/trigger-by.svg'
import { ReactComponent as TurnInto } from '../assets/icons/turn-into.svg'
import { ReactComponent as Underline } from '../assets/icons/underline.svg'
import { ReactComponent as UnlockOpen2 } from '../assets/icons/unlock-open-2.svg'
import { ReactComponent as Unpin } from '../assets/icons/unpin.svg'
import { ReactComponent as UpDiagonal } from '../assets/icons/up-diagonal.svg'
import { ReactComponent as Upload } from '../assets/icons/upload.svg'
import { ReactComponent as User } from '../assets/icons/user.svg'
import { ReactComponent as UserArrows } from '../assets/icons/user-arrows.svg'
import { ReactComponent as Users } from '../assets/icons/users.svg'
import { ReactComponent as Video } from '../assets/icons/video.svg'
import { ReactComponent as VideoBackward } from '../assets/icons/video_backward.svg'
import { ReactComponent as VideoForward } from '../assets/icons/video_forward.svg'
import { ReactComponent as VideoFullScreen } from '../assets/icons/video_fullscreen.svg'
import { ReactComponent as VideoPlay } from '../assets/icons/video_play.svg'
import { ReactComponent as ViewOff } from '../assets/icons/view-off.svg'
import { ReactComponent as Volume } from '../assets/icons/volume.svg'
import { ReactComponent as VolumeOff } from '../assets/icons/volume-off.svg'
import { ReactComponent as WarningCircle } from '../assets/icons/warning-circle.svg'
import { ReactComponent as Waterfall } from '../assets/icons/waterfall.svg'
import { ReactComponent as Webhook } from '../assets/icons/webhook.svg'
import { ReactComponent as WeightScale } from '../assets/icons/weight-scale.svg'
import { ReactComponent as Widget } from '../assets/icons/widget.svg'
import { ReactComponent as Workflow } from '../assets/icons/workflow.svg'
import { ReactComponent as WorkflowAction } from '../assets/icons/workflow-action.svg'
import { ReactComponent as World } from '../assets/icons/world.svg'
// Useful for iterating through all icons
export enum Icons {
	no_icon = 'no_icon',
	add_user = 'add_user',
	adjustments = 'adjustments',
	align_horizontal_center = 'align_horizontal_center',
	align_left = 'align_left',
	align_right = 'align_right',
	arrow_down = 'arrow_down',
	arrow_left = 'arrow_left',
	arrow_more = 'arrow_more',
	arrow_square = 'arrow_square',
	arrows_clockwise = 'arrows_clockwise',
	asc = 'asc',
	alert_triangle = 'alert_triangle',
	arrow_up = 'arrow_up',
	arrow_up_right = 'arrow_up_right',
	asterisk = 'asterisk',
	arr = 'arr',
	archive = 'archive',
	alert = 'alert',
	at = 'at',

	bell = 'bell',
	board = 'board',
	books = 'books',
	border_radius_side = 'border_radius_side',
	box_plus = 'box_plus',
	branch = 'branch',
	browser_code = 'browser_code',
	browser_page = 'browser_page',
	bullet_list = 'bullet_list',
	bullet_list_2 = 'bullet_list_2',
	bold = 'bold',

	calendar = 'calendar',
	calendar_check = 'calendar-check',
	calendar_edit = 'calendar_edit',
	calendar_empty = 'calendar_empty',
	calendar_clock = 'calendar_clock',
	camera_plus = 'camera-plus',
	calibration = 'calibration',
	calendar_smile = 'calendar_smile',
	calendar_people = 'calendar_people',
	calendar_weeks = 'calendar_weeks',
	calendar_world = 'calendar_world',
	check_circle = 'check_circle',
	check = 'check',
	chevron_down = 'chevron_down',
	chevron_right = 'chevron_right',
	chevron_left = 'chevron_left',
	chevron_pair_left = 'chevron_pair_left',
	chevron_pair_right = 'chevron_pair_right',
	chevron_up = 'chevron_up',
	close = 'close',
	clock = 'clock',
	clock_edit = 'clock_edit',
	clock_music = 'clock_music',
	collapse_arrow = 'collapse-arrow',
	command = 'command',
	company_attributes = 'company_attributes',
	copy = 'copy',
	crown = 'crown',
	_check_circle = '_check_circle',
	_cancel_calendar = '_cancel_calendar',
	checklist = 'checklist',
	cursor_click = 'cursor_click',
	created_version = 'created_version',
	calendar_search = 'calendar_search',

	data = 'data',
	dashboard = 'dashboard',
	default_logo = 'default_logo',
	design = 'design',
	document = 'document',
	dots_horizontal = 'dots_horizontal',
	dots_vertical = 'dots_vertical',
	dropper = 'dropper',
	desc = 'desc',
	drag_handle = 'drag_handle',
	drag_handle_horizontal = 'drag_handle_horizontal',
	download = 'download',
	drag = 'drag',
	drag_hand = 'drag_hand',
	details = 'details',

	enrichment = 'enrichment',
	enter = 'enter',
	expand_arrow = 'expand-arrow',
	exclamation_mark = 'exclamation_mark',
	expand = 'expand',
	edit = 'edit',
	eye = 'eye',
	eye_off = 'eye_off',
	eyedropper = 'eyedropper',
	edit2 = 'edit2',
	edit_pen = 'edit_pen',

	file_typo = 'file_typo',
	filter = 'filter',
	filter_funnel = 'filter_funnel',
	folder = 'folder',
	folder_off = 'folder_off',
	font = 'font',
	font_size = 'font_size',
	forms = 'forms',
	form_numbers = 'form_numbers',
	form_questions = 'form_questions',
	form_textarea = 'form_textarea',
	frame = 'frame',
	funding = 'funding',
	full_screen_size = 'full_screen_size',
	flag = 'flag',
	form_submission_trigger = 'form_submission_trigger',

	go_back = 'go_back',
	go_forward = 'go_forward',
	grid = 'grid',
	card_view = 'card_view',
	git_branch = 'git_branch',
	git_fork = 'git_fork',
	git_merge = 'git_merge',
	group = 'group',
	guide = 'guide',

	hashtag = 'hashtag',

	ifElse = 'ifElse',
	image = 'image',
	info = 'info',
	input = 'input',
	import_form = 'import_form',
	imported_form = 'imported_form',
	industry = 'industry',
	italics = 'italics',

	leads = 'leads',
	left_side = 'left_side',
	link = 'link',
	list = 'list',
	list_plus = 'list_plus',
	lightbulb = 'lightbulb',
	long_text = 'long_text',
	location = 'location',
	location_pin = 'location_pin',
	location_services = 'location_services',
	lock = 'lock',
	link_2 = 'link_2',
	link_form = 'link_form',
	link_off = 'link_off',

	magic_fix = 'magic_fix',
	microphone = 'microphone',
	mail = 'mail',
	mail_code = 'mail_code',
	mail_delete = 'mail_delete',
	mapping = 'mapping',
	map_marker_location = 'map_marker_location',
	map_simple_marker = 'map_simple_marker',
	minus = 'minus',
	more = 'more',
	mobile_size = 'mobile_size',
	modal_size = 'modal_size',
	multi_selection = 'multi_selection',
	meetings = 'meetings',

	new_form = 'new_form',
	no_leads = 'no_leads',
	notes = 'notes',
	numbered_list = 'numbered_list',

	open = 'open',
	open_link = 'open_link',

	paint_brush_angled = 'paint_brush_angled',
	paper_fold = 'paper_fold',
	palette = 'palette',
	parameters = 'parameters',
	person = 'person',
	person_badge = 'person_badge',
	person_id = 'person_id',
	phone = 'phone',
	picture = 'picture',
	pin = 'pin',
	plane = 'plane',
	plug = 'plug',
	plus = 'plus',
	play_box = 'play_box',
	published_version = 'published_version',
	play = 'play',
	pause = 'pause',

	quotes = 'quotes',
	queues_arrows = 'queues_arrows',

	restricted = 'restricted',
	right_side = 'right_side',
	rocket = 'rocket',
	role = 'role',
	route = 'route',
	route_right = 'route_right',
	refresh = 'refresh',
	route2 = 'route2',

	share = 'share',
	share2 = 'share2',
	shine = 'shine',
	search = 'search',
	sidebar = 'sidebar',
	setting = 'setting',
	sleep_moon = 'sleep_moon',
	short_text = 'short_text',
	single_select = 'single_select',
	sorting = 'sorting',
	sparkles = 'sparkles',
	sparkles2 = 'sparkles2',
	sparkles3 = 'sparkles3',
	sun = 'sun',
	switch_arrow = 'switch_arrow',
	speaker = 'speaker',
	striked_text = 'striked_text',
	send = 'send',
	sorting2 = 'sorting2',
	send_mail = 'send_mail',

	table = 'table',
	tag = 'tag',
	textbox_save = 'textbox_save',
	textbox_upload = 'textbox_upload',
	test_tube = 'test_tube',
	text = 'text',
	text2 = 'text2',
	toggle = 'toggle',
	trash = 'trash',
	tree = 'tree',
	turn_into = 'turn_into',
	timer = 'timer',
	trigger_by = 'trigger_by',
	time_reload = 'time_reload',

	underline = 'underline',
	unlock_open2 = 'unlock_open2',
	unpin = 'unpin',
	up_diagonal = 'up_diagonal',
	upload = 'upload',
	user = 'user',
	user_arrows = 'user_arrows',
	users = 'users',

	video = 'video',
	video_backward = 'video_backward',
	video_forward = 'video_forward',
	video_fullscreen = 'video_fullscreen',
	video_play = 'video_play',
	view_off = 'view_off',
	volume = 'volume',
	volume_off = 'volume_off',
	warning_circle = 'warning_circle',
	waterfall = 'waterfall',
	webhook = 'webhook',
	weight_scale = 'weight_scale',
	widget = 'widget',
	workflow_action = 'workflow_action',
	world = 'world',
	workflow = 'workflow',
}

export type IconType = `${Icons}`

export interface Props extends IconProps {
	icon: IconType
}

export const IconSwitcher = React.forwardRef(({ icon, ...props }: Props) => {
	switch (icon) {
		case 'no_icon':
			return <></>
		case 'adjustments':
			return <Icon as={Adjustments} {...props} />
		case 'align_horizontal_center':
			return <Icon as={AlignHCenter} {...props} />
		case 'alert_triangle':
			return <Icon as={AlertTriangle} {...props} />
		case 'align_left':
			return <Icon as={AlignHLeft} {...props} />
		case 'align_right':
			return <Icon as={AlignHRight} {...props} />
		case 'arrows_clockwise':
			return <Icon as={ArrowsClockwise} {...props} />
		case 'arrow_down':
			return <Icon as={ArrowDown} {...props} />
		case 'arrow_left':
			return <Icon as={ArrowLeft} {...props} />
		case 'arrow_more':
			return <Icon as={ArrowMore} {...props} />
		case 'arrow_square':
			return <Icon as={ArrowSquare} {...props} />
		case 'arrow_up':
			return <Icon as={ArrowUp} {...props} />
		case 'arrow_up_right':
			return <Icon as={ArrowUpRight} {...props} />
		case 'asterisk':
			return <Icon as={Asterisk} {...props} />
		case 'asc':
			return <Icon as={Asc} {...props} />
		case 'arr':
			return <Icon as={ARR} {...props} />
		case 'archive':
			return <Icon as={Archive} {...props} />
		case 'alert':
			return <Icon as={Alert} {...props} />
		case 'add_user':
			return <Icon as={AddUser} {...props} />
		case 'at':
			return <Icon as={At} {...props} />

		case 'bell':
			return <Icon as={Bell} {...props} />
		case 'board':
			return <Icon as={Board} {...props} />
		case 'books':
			return <Icon as={Books} {...props} />
		case 'border_radius_side':
			return <Icon as={BorderRadiusSide} {...props} />
		case 'box_plus':
			return <Icon as={BoxPlus} {...props} />
		case 'branch':
			return <Icon as={Branch} {...props} />
		case 'browser_page':
			return <Icon as={BrowserPage} {...props} />
		case 'browser_code':
			return <Icon as={BrowserCode} {...props} />
		case 'bullet_list':
			return <Icon as={BulletList} {...props} />
		case 'bullet_list_2':
			return <Icon as={BulletList2} {...props} />
		case 'bold':
			return <Icon as={Bold} {...props} />

		case 'calendar':
			return <Icon as={Calendar} {...props} />
		case 'calendar-check':
			return <Icon as={CalendarCheck} {...props} />
		case 'calendar_edit':
			return <Icon as={CalendarEdit} {...props} />
		case 'calendar_empty':
			return <Icon as={CalendarEmpty} {...props} />
		case 'calendar_clock':
			return <Icon as={CalendarClock} {...props} />
		case 'camera-plus':
			return <Icon as={CameraPlus} {...props} />
		case 'calibration':
			return <Icon as={Calibration} {...props} />
		case 'card_view':
			return <Icon as={CardView} {...props} />
		case 'calendar_smile':
			return <Icon as={CalendarSmile} {...props} />
		case 'calendar_world':
			return <Icon as={CalendarWorld} {...props} />
		case 'calendar_weeks':
			return <Icon as={CalendarWeeks} {...props} />
		case 'calendar_people':
			return <Icon as={CalendarPeople} {...props} />
		case 'check_circle':
			return <Icon as={CheckCircle} {...props} />
		case 'check':
			return <Icon as={Check} {...props} />
		case 'clock':
			return <Icon as={Clock} {...props} />
		case 'clock_music':
			return <Icon as={ClockMusic} {...props} />
		case 'clock_edit':
			return <Icon as={ClockEdit} {...props} />
		case 'chevron_down':
			return <Icon as={ChevronDown} {...props} />
		case 'chevron_right':
			return <Icon as={ChevronRight} {...props} />
		case 'chevron_left':
			return <Icon as={ChevronLeft} {...props} />
		case 'chevron_pair_left':
			return <Icon as={ChevronPairLeft} {...props} />
		case 'chevron_pair_right':
			return <Icon as={ChevronPairRight} {...props} />
		case 'chevron_up':
			return <Icon as={ChevronUp} {...props} />
		case 'close':
			return <Icon as={Close} {...props} />
		case '_check_circle':
			return <Icon as={_CheckCircle} {...props} />
		case '_cancel_calendar':
			return <Icon as={_CancelCalendar} {...props} />
		case 'checklist':
			return <Icon as={Checklist} {...props} />
		case 'created_version':
			return <Icon as={CreatedVersion} {...props} />
		case 'cursor_click':
			return <Icon as={CursorClick} {...props} />
		case 'collapse-arrow':
			return <Icon as={CollapseArrow} {...props} />
		case 'command':
			return <Icon as={Command} {...props} />
		case 'company_attributes':
			return <Icon as={CompanyAttributes} {...props} />
		case 'copy':
			return <Icon as={Copy} {...props} />
		case 'crown':
			return <Icon as={Crown} {...props} />
		case 'calendar_search':
			return <Icon as={CalendarSearch} {...props} />

		case 'data':
			return <Icon as={Data} {...props} />
		case 'dashboard':
			return <Icon as={Dashboard} {...props} />
		case 'default_logo':
			return <Icon as={DefaultLogo} {...props} />
		case 'design':
			return <Icon as={Design} {...props} />
		case 'document':
			return <Icon as={Document} {...props} />
		case 'dots_horizontal':
			return <Icon as={DotsHorizontal} {...props} />
		case 'dots_vertical':
			return <Icon as={DotsVertical} {...props} />
		case 'dropper':
			return <Icon as={Dropper} {...props} />
		case 'drag_hand':
			return <Icon as={DragHand} {...props} />
		case 'drag_handle':
			return <Icon as={DragHandle} {...props} />
		case 'drag_handle_horizontal':
			return <Icon as={DragHandleHorizontal} {...props} />
		case 'desc':
			return <Icon as={Desc} {...props} />
		case 'download':
			return <Icon as={Download} {...props} />
		case 'drag':
			return <Icon as={Drag} {...props} />
		case 'details':
			return <Icon as={Details} {...props} />

		case 'expand-arrow':
			return <Icon as={ExpandArrow} {...props} />
		case 'expand':
			return <Icon as={Expand} {...props} />
		case 'exclamation_mark':
			return <Icon as={ExclamationMark} {...props} />
		case 'edit':
			return <Icon as={Edit} {...props} />
		case 'enrichment':
			return <Icon as={Enrichment} {...props} />
		case 'enter':
			return <Icon as={Enter} {...props} />
		case 'eye':
			return <Icon as={Eye} {...props} />
		case 'eye_off':
			return <Icon as={EyeOff} {...props} />
		case 'eyedropper':
			return <Icon as={Eyedropper} {...props} />
		case 'edit2':
			return <Icon as={Edit2} {...props} />
		case 'edit_pen':
			return <Icon as={EditPen} {...props} />
		case 'file_typo':
			return <Icon as={FileTypo} {...props} />
		case 'filter':
			return <Icon as={Filter} {...props} />
		case 'filter_funnel':
			return <Icon as={FilterFunnel} {...props} />
		case 'folder':
			return <Icon as={Folder} {...props} />
		case 'folder_off':
			return <Icon as={FolderOff} {...props} />
		case 'font':
			return <Icon as={Font} {...props} />
		case 'font_size':
			return <Icon as={FontSize} {...props} />
		case 'forms':
			return <Icon as={Forms} {...props} />
		case 'form_numbers':
			return <Icon as={FormNumbers} {...props} />
		case 'form_questions':
			return <Icon as={FormQuestions} {...props} />
		case 'form_textarea':
			return <Icon as={FormTextarea} {...props} />
		case 'frame':
			return <Icon as={Frame} {...props} />
		case 'funding':
			return <Icon as={Funding} {...props} />
		case 'full_screen_size':
			return <Icon as={FullScreenSize} {...props} />
		case 'flag':
			return <Icon as={Flag} {...props} />
		case 'form_submission_trigger':
			return <Icon as={FormSubmissionTrigger} {...props} />

		case 'git_branch':
			return <Icon as={GitBranch} {...props} />
		case 'git_fork':
			return <Icon as={GitFork} {...props} />
		case 'git_merge':
			return <Icon as={GitMerge} {...props} />
		case 'go_back':
			return <Icon as={GoBack} {...props} />
		case 'go_forward':
			return <Icon as={GoForward} {...props} />
		case 'grid':
			return <Icon as={Grid} {...props} />

		case 'group':
			return <Icon as={Group} {...props} />
		case 'guide':
			return <Icon as={Guide} {...props} />

		case 'hashtag':
			return <Icon as={HashTag} {...props} />
		case 'shine':
			return <Icon as={Shine} {...props} />
		case 'image':
			return <Icon as={Image} {...props} />
		case 'info':
			return <Icon as={Info} {...props} />
		case 'input':
			return <Icon as={Input} {...props} />
		case 'import_form':
			return <Icon as={ImportForm} {...props} />
		case 'imported_form':
			return <Icon as={ImportedForm} {...props} />
		case 'industry':
			return <Icon as={Industry} {...props} />
		case 'italics':
			return <Icon as={Italics} {...props} />

		case 'left_side':
			return <Icon as={LeftSide} {...props} />
		case 'leads':
			return <Icon as={Leads} {...props} />
		case 'link':
			return <Icon as={Link} {...props} />
		case 'list':
			return <Icon as={List} {...props} />
		case 'list_plus':
			return <Icon as={ListPlus} {...props} />
		case 'lightbulb':
			return <Icon as={Lightbulb} {...props} />
		case 'ifElse':
			return <Icon as={IfElse} {...props} />
		case 'long_text':
			return <Icon as={LongText} {...props} />
		case 'location':
			return <Icon as={Location} {...props} />
		case 'location_pin':
			return <Icon as={LocationPin} {...props} />
		case 'location_services':
			return <Icon as={LocationServices} {...props} />
		case 'lock':
			return <Icon as={Lock} {...props} />
		case 'link_2':
			return <Icon as={Link2} {...props} />
		case 'link_form':
			return <Icon as={LinkForm} {...props} />
		case 'link_off':
			return <Icon as={LinkOff} {...props} />

		case 'magic_fix':
			return <Icon as={MagicFix} {...props} />
		case 'mail':
			return <Icon as={Mail} {...props} />
		case 'mail_code':
			return <Icon as={MailCode} {...props} />
		case 'mail_delete':
			return <Icon as={MailDelete} {...props} />
		case 'mapping':
			return <Icon as={Mapping} {...props} />
		case 'map_marker_location':
			return <Icon as={MapMarker} {...props} />
		case 'map_simple_marker':
			return <Icon as={MapSimpleMarker} {...props} />
		case 'minus':
			return <Icon as={Minus} {...props} />
		case 'more':
			return <Icon as={More} {...props} />
		case 'mobile_size':
			return <Icon as={MobileSize} {...props} />
		case 'modal_size':
			return <Icon as={ModalSize} {...props} />
		case 'microphone':
			return <Icon as={Microphone} {...props} />
		case 'multi_selection':
			return <Icon as={MultSelect} {...props} />
		case 'meetings':
			return <Icon as={Meetings} {...props} />

		case 'new_form':
			return <Icon as={NewForm} {...props} />
		case 'no_leads':
			return <Icon as={NoLeads} {...props} />
		case 'notes':
			return <Icon as={Notes} {...props} />
		case 'numbered_list':
			return <Icon as={NumberedList} {...props} />

		case 'open':
			return <Icon as={Open} {...props} />
		case 'open_link':
			return <Icon as={OpenLink} {...props} />

		case 'paint_brush_angled':
			return <Icon as={PaintBrushAngled} {...props} />
		case 'paper_fold':
			return <Icon as={PaperFold} {...props} />
		case 'palette':
			return <Icon as={Palette} {...props} />
		case 'parameters':
			return <Icon as={Parameters} {...props} />
		case 'person':
			return <Icon as={Person} {...props} />
		case 'person_badge':
			return <Icon as={PersonBadge} {...props} />
		case 'person_id':
			return <Icon as={PersonId} {...props} />
		case 'phone':
			return <Icon as={Phone} {...props} />
		case 'picture':
			return <Icon as={Picture} {...props} />
		case 'pin':
			return <Icon as={Pin} {...props} />
		case 'plane':
			return <Icon as={Plane} {...props} />
		case 'plug':
			return <Icon as={Plug} {...props} />
		case 'plus':
			return <Icon as={Plus} {...props} />
		case 'play_box':
			return <Icon as={PlayBox} {...props} />
		case 'published_version':
			return <Icon as={PublishedVersion} {...props} />
		case 'play':
			return <Icon as={Play} {...props} />
		case 'pause':
			return <Icon as={Pause} {...props} />

		case 'quotes':
			return <Icon as={Quotes} {...props} />
		case 'queues_arrows':
			return <Icon as={QueuesArrows} {...props} />

		case 'right_side':
			return <Icon as={RightSide} {...props} />
		case 'rocket':
			return <Icon as={Rocket} {...props} />
		case 'role':
			return <Icon as={Role} {...props} />
		case 'route':
			return <Icon as={Route} {...props} />
		case 'refresh':
			return <Icon as={Refresh} {...props} />
		case 'restricted':
			return <Icon as={Restricted} {...props} />
		case 'route2':
			return <Icon as={Route2} {...props} />
		case 'route_right':
			return <Icon as={RouteRight} {...props} />

		case 'share':
			return <Icon as={Share} {...props} />
		case 'share2':
			return <Icon as={Share2} {...props} />
		case 'search':
			return <Icon as={Search} {...props} />
		case 'setting':
			return <Icon as={Settings} {...props} />
		case 'sidebar':
			return <Icon as={Sidebar} {...props} />
		case 'sleep_moon':
			return <Icon as={SleepMoon} {...props} />
		case 'short_text':
			return <Icon as={ShortText} {...props} />
		case 'single_select':
			return <Icon as={SingleSelect} {...props} />
		case 'sorting':
			return <Icon as={Sorting} {...props} />
		case 'sparkles':
			return <Icon as={Sparkles} {...props} />
		case 'sparkles2':
			return <Icon as={Sparkles2} {...props} />
		case 'sparkles3':
			return <Icon as={Sparkles3} {...props} />
		case 'sun':
			return <Icon as={Sun} {...props} />
		case 'switch_arrow':
			return <Icon as={SwitchArrow} {...props} />
		case 'speaker':
			return <Icon as={Speaker} {...props} />
		case 'striked_text':
			return <Icon as={StrikedText} {...props} />
		case 'send':
			return <Icon as={Send} {...props} />
		case 'sorting2':
			return <Icon as={Sorting2} {...props} />
		case 'send_mail':
			return <Icon as={SendMail} {...props} />

		case 'table':
			return <Icon as={Table} {...props} />
		case 'tag':
			return <Icon as={Tag} {...props} />
		case 'textbox_save':
			return <Icon as={TextboxSave} {...props} />
		case 'textbox_upload':
			return <Icon as={TextboxUpload} {...props} />
		case 'test_tube':
			return <Icon as={TestTube} {...props} />
		case 'text':
			return <Icon as={Text} {...props} />
		case 'text2':
			return <Icon as={Text2} {...props} />
		case 'toggle':
			return <Icon as={Toggle} {...props} />
		case 'trash':
			return <Icon as={Trash} {...props} />
		case 'tree':
			return <Icon as={Tree} {...props} />
		case 'turn_into':
			return <Icon as={TurnInto} {...props} />
		case 'timer':
			return <Icon as={Timer} {...props} />
		case 'trigger_by':
			return <Icon as={TriggerBy} {...props} />
		case 'time_reload':
			return <Icon as={TimeReload} {...props} />

		case 'underline':
			return <Icon as={Underline} {...props} />
		case 'unlock_open2':
			return <Icon as={UnlockOpen2} {...props} />
		case 'unpin':
			return <Icon as={Unpin} {...props} />
		case 'up_diagonal':
			return <Icon as={UpDiagonal} {...props} />
		case 'upload':
			return <Icon as={Upload} {...props} />
		case 'user':
			return <Icon as={User} {...props} />
		case 'user_arrows':
			return <Icon as={UserArrows} {...props} />
		case 'users':
			return <Icon as={Users} {...props} />

		case 'video':
			return <Icon as={Video} {...props} />
		case 'video_play':
			return <Icon as={VideoPlay} {...props} />
		case 'video_forward':
			return <Icon as={VideoForward} {...props} />
		case 'video_fullscreen':
			return <Icon as={VideoFullScreen} {...props} />
		case 'video_backward':
			return <Icon as={VideoBackward} {...props} />
		case 'view_off':
			return <Icon as={ViewOff} {...props} />
		case 'volume':
			return <Icon as={Volume} {...props} />
		case 'volume_off':
			return <Icon as={VolumeOff} {...props} />

		case 'warning_circle':
			return <Icon as={WarningCircle} {...props} />
		case 'waterfall':
			return <Icon as={Waterfall} {...props} />
		case 'webhook':
			return <Icon as={Webhook} {...props} />
		case 'weight_scale':
			return <Icon as={WeightScale} {...props} />
		case 'widget':
			return <Icon as={Widget} {...props} />
		case 'workflow_action':
			return <Icon as={WorkflowAction} {...props} />
		case 'world':
			return <Icon as={World} {...props} />
		case 'workflow':
			return <Icon as={Workflow} {...props} />
	}
})

IconSwitcher.displayName = 'IconSwitcher'
