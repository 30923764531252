import { Avatar, Flex, IconButton, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch } from '@/app/hooks'
import { useTeam } from '@/app/user/api/teams/selectors'
import { userActions } from '@/app/user/slice'
import { useTeamMembers } from '@/common/hooks/useTeamMembers'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	isExpanded: boolean
}

export const SidebarHeader = ({ isExpanded }: Props) => {
	const team = useTeam()?.team
	const members = useTeamMembers()
	const dispatch = useAppDispatch()

	return (
		<Flex
			w="100%"
			alignItems="center"
			px={2}
			mt={isExpanded ? '14px' : '8px'}
			direction={isExpanded ? 'row' : 'column-reverse'}
			justifyContent="space-between"
			gap="14px"
		>
			<Flex gap="12px" alignItems="center">
				<Avatar
					h="32px"
					w="32px"
					src={team?.icon || ''}
					name={team?.name || ''}
				/>
				{isExpanded && (
					<Flex flexDir="column">
						<Text variant={TEXT_ENUM.medium14}>{team?.name} </Text>
						<Text
							variant={TEXT_ENUM.regular12}
							color={COLORS.gray[9]}
							overflow="visible"
							textOverflow="clip"
							noOfLines={1}
						>
							{members?.length || 'Loading'} members
						</Text>
					</Flex>
				)}
			</Flex>
			<IconButton
				aria-label=""
				variant={BTN.primary_icon}
				_hover={{ bg: ColorTokens.card_secondary }}
				onClick={() =>
					dispatch(userActions.setIsHomeSidebarExpanded(!isExpanded))
				}
			>
				<GetIcon fontSize="24px" color={COLORS.gray[9]} icon={Icons.sidebar} />
			</IconButton>
		</Flex>
	)
}
