import { Center, Flex, Image, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'
import { SHADOWS } from '@ds/tokens/legacy/shadows'

import ScreenSizeMessage from '@/assets/images/screen-size-message.png'
import { DefaultLogoWithText } from '@/components/Logo'
import { TEXT_ENUM } from '@/styles/components/text'

export const ScreenSizeConstraints = () => {
	return (
		<Flex flexDir="column" w="full" alignItems="center" py="60px" px="48px">
			<Flex px="24px" h="52px" alignItems="center">
				<DefaultLogoWithText
					w="140px"
					h="24px"
					color={ColorTokens.text_primary}
				/>
			</Flex>

			<Center
				w="full"
				maxW="592px"
				mt="32px"
				flexDir="column"
				color={COLORS.background[2]}
				py="48px"
				px="32px"
				bg="white"
				borderRadius="12px"
				boxShadow={SHADOWS.depth0}
			>
				<Image src={ScreenSizeMessage} maxW="128px" />
				<Text
					variant={TEXT_ENUM.semibold18}
					color={COLORS.gray[12]}
					textAlign="center"
					minH="28px"
					lineHeight="24px"
					mt="24px"
				>
					We are not supporting all screen sizes yet
				</Text>
				<Text
					variant={TEXT_ENUM.regular14}
					color={COLORS.gray[10]}
					textAlign="center"
					lineHeight="20px"
					mt="2px"
				>
					Please use a device with a larger screen to access the Default
					platform
				</Text>
			</Center>
		</Flex>
	)
}
