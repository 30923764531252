import {
	KnockFeedProvider,
	KnockProvider as KnockProviderSDK,
} from '@knocklabs/react'
import { ReactNode } from 'react'

import { KNOCK_FEED_CHANNEL_ID, KNOCK_PUBLIC_API_KEY } from '@/app/config'
import { useMember } from '@/app/user/api/members/selector'

interface KnockProviderProps {
	children: ReactNode
}

export const KnockProvider = ({ children }: KnockProviderProps) => {
	const member = useMember().member

	if (!member) {
		return <>{children}</>
	}

	return (
		<KnockProviderSDK
			apiKey={KNOCK_PUBLIC_API_KEY}
			userId={member.id}
			userToken={member.knockToken || undefined}
		>
			<KnockFeedProvider feedId={KNOCK_FEED_CHANNEL_ID}>
				{children}
			</KnockFeedProvider>
		</KnockProviderSDK>
	)
}
