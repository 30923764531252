import { ddsTokens } from './generated/tokens'

type DDSTokens = typeof ddsTokens

type DDSTheme = keyof DDSTokens
const DDSTheme = Object.keys(ddsTokens).reduce(
	(acc, key) => ({ ...acc, [key]: key }),
	{},
) as { [K in DDSTheme]: K }

const getThemeTokens = (theme: DDSTheme) => {
	return ddsTokens[theme]
}

const primitiveTokens = ddsTokens[DDSTheme.light].primitive // Primitive tokens are always the same

const typographyTokens = ddsTokens[DDSTheme.light].typography // Typography tokens should always be the same

enum DDSSizeTokens {
	xs = 'xs',
	s = 's',
	m = 'm',
	l = 'l',
	xl = 'xl',
	'2xl' = '2xl',
}

export {
	DDSSizeTokens,
	DDSTheme,
	type DDSTokens,
	ddsTokens,
	getThemeTokens,
	primitiveTokens,
	typographyTokens,
}
