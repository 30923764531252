import { Box, Flex, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'
import { Link } from 'react-router-dom'
import { GetIcon } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

import { SidebarOptionType } from '../hooks/useSidebarOptions'
import { SidebarNavItemSuboptions } from './SidebarNavItemSuboptions'

interface Props extends SidebarOptionType {
	isExpanded: boolean
	badge?: number
	onClick?: (e: React.MouseEvent) => void
}

export const SidebarNavItem = ({
	title,
	isExpanded,
	navTo,
	icon,
	suboptions,
	isActive,
	isNewTab,
	badge,
	onClick,
}: Props) => {
	const anyChildActive = suboptions?.some((suboption) => suboption.isActive)
	const showActiveStyle = isExpanded ? isActive && !anyChildActive : isActive

	const suboptionsComponent = (
		<SidebarNavItemSuboptions
			suboptions={suboptions}
			isActive={isActive}
			isExpanded={isExpanded}
		/>
	)

	const content = (
		<Flex
			w="full"
			h="36px"
			flexDir="row"
			alignItems="center"
			justifyContent={isExpanded ? 'flex-start' : 'center'}
			borderRadius="10px"
			px="8px"
			gap="8px"
			color={COLORS.background[2]}
			bg={showActiveStyle ? ColorTokens.card_secondary : 'transparent'}
			_hover={{
				bg: ColorTokens.card_secondary,
			}}
			position="relative"
			userSelect="none"
		>
			<GetIcon
				fontSize="16px"
				icon={icon}
				color={showActiveStyle ? 'currentcolor' : COLORS.background[5]}
			/>
			{isExpanded && <Text variant={TEXT_ENUM.medium14}>{title}</Text>}
			{badge !== undefined && badge > 0 && (
				<Flex
					position="absolute"
					top={isExpanded ? '10px' : '-2px'}
					right={isExpanded ? '12px' : '-2px'}
					minW="16px"
					px={0.5}
					borderRadius="4px"
					bg={COLORS.purple[9]}
					alignItems="center"
					justifyContent="center"
				>
					<Text variant={TEXT_ENUM.bold12} color="white">
						{badge > 9 ? '9+' : badge}
					</Text>
				</Flex>
			)}
		</Flex>
	)

	return (
		<Flex w="full" direction="column">
			{onClick && (
				<Box as="button" w="full" onClick={onClick}>
					{content}
				</Box>
			)}
			{!onClick && (
				<Link
					to={navTo}
					target={isNewTab ? '_blank' : undefined}
					rel={isNewTab ? 'noopener noreferrer' : undefined}
				>
					{content}
				</Link>
			)}
			{suboptionsComponent}
		</Flex>
	)
}
