import { progressAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/legacy/colors'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	progressAnatomy.keys,
)

export const progressTheme = defineMultiStyleConfig({
	sizes: {
		xss: {
			track: {
				h: '2px',
			},
		},
	},
	baseStyle: () => ({
		track: {
			bg: COLORS.background[11],
		},
		filledTrack: {
			bg: COLORS.purple[9],
		},
	}),
	defaultProps: {
		size: 'xss',
	},
})
