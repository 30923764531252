import Badge from '@ds/components/Badge'
import { COLORS } from '@ds/tokens/legacy/colors'

export enum BadgePillType {
	neutral = 'neutral',
	info = 'info',
	positive = 'positive',
	negative = 'negative',
	warning = 'warning',
}

const badgePillStyles: Record<
	BadgePillType,
	{ borderColor: string; color: string; backgroundColor: string }
> = {
	neutral: {
		backgroundColor: COLORS.background[12],
		color: COLORS.background[5],
		borderColor: COLORS.background[10],
	},
	info: {
		backgroundColor: COLORS.purple[2],
		color: COLORS.purple[9],
		borderColor: COLORS.purple[4],
	},
	positive: {
		backgroundColor: COLORS.green[2],
		color: COLORS.green[9],
		borderColor: COLORS.green[4],
	},
	negative: {
		backgroundColor: COLORS.red[2],
		color: COLORS.red[9],
		borderColor: COLORS.red[4],
	},
	warning: {
		backgroundColor: COLORS.orange[2],
		color: COLORS.orange[9],
		borderColor: COLORS.orange[4],
	},
}

type BadgePillProps = {
	type: BadgePillType
	text: string
}

export const BadgePill = ({ type, text }: BadgePillProps) => {
	const styles = badgePillStyles[type]

	return (
		<Badge
			backgroundColor={styles.backgroundColor}
			border="1px solid"
			borderColor={styles.borderColor}
			text={text}
			color={styles.color}
		/>
	)
}
