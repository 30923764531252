import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { WEIGHT } from '../../../tokens/legacy/typography'

dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
	timezone: string
	format: string
}

export const TimezoneOptionSuffix = ({ timezone, format }: Props) => {
	return (
		<Text opacity={0.4} fontWeight={WEIGHT.hairline}>
			{dayjs().tz(timezone).format(format)}
		</Text>
	)
}
