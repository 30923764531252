import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BlockType } from '../types'

export interface BlockSelect {
	pageId: number | string | null
	newPageId?: string | null
	blockType: BlockType | null
	questionId?: string | null
	meta?: string | null
}
export interface FormsState {
	selectedFormId: string | null
	blockInfo: BlockSelect | null
	isDragging: boolean
	lead_attribute_edited: number | null
	selectedAction: string | null
	haveQuestionsUpdated: boolean
	/** @deprecated */
	selectedBlock: string | null
}

export const initialState: FormsState = {
	selectedFormId: null,
	lead_attribute_edited: null,
	blockInfo: null,
	isDragging: false,
	selectedAction: null,
	haveQuestionsUpdated: false,
	/** @deprecated */
	selectedBlock: null,
}

export const formsSlice = createSlice({
	name: 'forms',
	initialState,
	reducers: {
		setSelectFormId: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedFormId = payload
		},
		setBlockInfo: (
			state,
			{
				payload: { blockInfo },
			}: PayloadAction<{ blockInfo: BlockSelect | null }>,
		) => {
			state.blockInfo = blockInfo
		},
		setIsDragging: (state, action: PayloadAction<boolean>) => {
			state.isDragging = action.payload
		},

		updateLeadAttribute: (state, { payload }: PayloadAction<number | null>) => {
			state.lead_attribute_edited = payload
		},
		updateSelectedAction: (
			state,
			{ payload }: PayloadAction<string | null>,
		) => {
			state.selectedAction = payload
		},
		setHaveQuestionsUpdated: (state, { payload }: PayloadAction<boolean>) => {
			state.haveQuestionsUpdated = payload
		},
		/** @deprecated - Kept for EditorTabOld */
		selectBlock: (
			state,
			{ payload: { path } }: PayloadAction<{ path: string | null }>,
		) => {
			state.selectedBlock = path
		},
	},
})
export const formsActions = formsSlice.actions
export const formsReducer = formsSlice.reducer
