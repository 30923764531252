import * as Sentry from '@sentry/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'

import { useGetMemberQuery } from '@/app/user/api/members'
import { useGetTeamQuery } from '@/app/user/api/teams'
import { LoadingPage } from '@/components/Loading'
import { identifyUserWithGtm } from '@/modules/app/utils/identifyUserWithGtm'
import { getToken } from '@/utils/helpers/token'

import { identifyUserWithLaunchDarkly } from '../utils/identifyUserWithLaunchDarkly'

type Props = {
	children: React.ReactNode
}

export const AuthPartial = ({ children }: Props) => {
	const token = getToken()
	const { data, isLoading, error, isFetching } = useGetMemberQuery(undefined, {
		skip: !token,
	})
	const ldClient = useLDClient()
	const isDataPending = isLoading || isFetching
	const isAuthenticated = !!data?.id

	useGetTeamQuery(data?.team_id, {
		skip: !data?.team_id,
	})

	useEffect(() => {
		if (isDataPending) return
		if (isAuthenticated && !error) {
			identifyUserWithLaunchDarkly(data, ldClient)
			Sentry.setUser({
				email: data?.email,
			})
			identifyUserWithGtm({
				userId: data.id,
				teamId: data.team_id,
				email: data.email,
			})
		}
	}, [data, error, ldClient, isDataPending, isAuthenticated])

	if (isDataPending && !isAuthenticated) {
		return <LoadingPage />
	}

	return <>{children}</>
}
