import { Box, Flex, Text } from '@chakra-ui/react'
import { GetIcon } from '@ds/Icons'
import { Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/legacy/colors'
import {
	FilterStatus,
	NotificationFeed,
	RenderItemProps,
	useKnockFeed,
} from '@knocklabs/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { formatUTCDate } from 'shared-utils/src/helper/format-date'

import { TEXT_ENUM } from '@/styles/components/text'

import { NotificationCenterHeader } from './NotificationCenterHeader'
import { NotificationItem } from './NotificationItem'

export const NotificationCenter = ({
	setIsOpen,
}: {
	setIsOpen: (isOpen: boolean) => void
}) => {
	const [storedFilterStatus, setStoredFilterStatus] = useState<FilterStatus>(
		FilterStatus.Unread,
	)

	const { useFeedStore } = useKnockFeed()
	const { items } = useFeedStore()

	const groupedNotifications = groupNotificationsByDate(items)

	return (
		<Box h="calc(100vh - 40px)" w="100%">
			<NotificationFeed
				initialFilterStatus={FilterStatus.Unread}
				renderHeader={(props) => {
					const { filterStatus } = props
					setStoredFilterStatus(filterStatus)
					return <NotificationCenterHeader {...props} setIsOpen={setIsOpen} />
				}}
				renderItem={({ item }) =>
					item.id === items[0].id && (
						<Flex direction="column" w="100%">
							{Object.entries(groupedNotifications).map(
								([date, notifications]) => (
									<Flex key={date} direction="column" w="100%">
										<Flex
											w="100%"
											bg={COLORS.background[12]}
											px={3}
											py={2}
											borderBottom={`1px solid ${COLORS.gray[4]}`}
										>
											<Text
												variant={TEXT_ENUM.medium12}
												color={COLORS.background[6]}
											>
												{date}
											</Text>
										</Flex>
										{notifications.map((item) => (
											<NotificationItem
												key={item.id}
												item={item}
												setIsOpen={setIsOpen}
											/>
										))}
									</Flex>
								),
							)}
						</Flex>
					)
				}
				EmptyComponent={
					<Flex
						direction="column"
						w="100%"
						alignItems="center"
						justifyContent="center"
						py="20px"
						px="20px"
					>
						<Flex
							w="full"
							h="calc(100vh - 160px)"
							border={`2px dashed ${COLORS.background[11]}`}
							borderRadius="12px"
							direction="column"
							alignItems="center"
							justifyContent="center"
							gap={2}
							color={COLORS.background[7]}
						>
							<GetIcon boxSize={10} icon={Icons.sleep_moon} />
							<Text variant={TEXT_ENUM.medium18}>
								No{' '}
								{storedFilterStatus !== FilterStatus.All
									? storedFilterStatus.toLowerCase()
									: ''}{' '}
								notifications
							</Text>
						</Flex>
					</Flex>
				}
			/>
		</Box>
	)
}

type GroupedNotifications = {
	[key: string]: RenderItemProps['item'][]
}

const groupNotificationsByDate = (items: RenderItemProps['item'][]) => {
	const grouped: GroupedNotifications = {}

	items.forEach((item) => {
		let date = formatUTCDate({
			date: item.inserted_at,
			showTodayYeserday: true,
			format: 'MMM D, YYYY',
		})

		if (dayjs(date).isToday()) {
			date = 'Today'
		}

		if (dayjs(date).isYesterday()) {
			date = 'Yesterday'
		}

		if (!grouped[date]) {
			grouped[date] = []
		}
		if (grouped[date].find((i) => i.id === item.id)) {
			return
		}
		grouped[date].push(item)
	})

	return grouped
}
