import type { ComponentStyleConfig } from '@chakra-ui/react'

// import { baseStyle } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '../../../tokens/legacy/colors'

const TextArea: ComponentStyleConfig = {
	baseStyle: () => ({
		color: 'text',
		background: COLORS.background[12],
		px: 3,
		borderRadius: 'md',
		borderWidth: '1px',
		borderColor: ColorTokens.border_primary,
		fontWeight: 400,
		fontSize: 'sm',
		_focus: {
			boxShadow: 'focus',
		},
	}),
	defaultProps: {
		size: null,
		// variant: baseStyle,
	},
}

export default TextArea
