interface GTMAnalyticsUser {
	userId: string
	teamId: string | number | null
	email: string
}

interface DataLayerEvent {
	event: string
	[key: string]: string | number | null
}

declare global {
	interface Window {
		dataLayer: DataLayerEvent[]
	}
}

export const identifyUserWithGtm = (member: GTMAnalyticsUser) => {
	try {
		window.dataLayer.push({
			event: 'identify_user',
			...member,
		})
	} catch (error) {
		console.error('Failed to identify user in analytics:', error)
	}
}
