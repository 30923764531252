import type { ComponentStyleConfig } from '@chakra-ui/react'

import { COLORS, ColorTokens } from '../../../tokens/legacy/colors'

const Tooltip: ComponentStyleConfig = {
	baseStyle: () => {
		return {
			bg: COLORS.background[3],
			color: ColorTokens.white,
		}
	},
}

export default Tooltip
