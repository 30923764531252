import { Flex, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/legacy/colors'
import { FilterStatus, useKnockFeed } from '@knocklabs/react'

import { BadgePill, BadgePillType } from '@/common/components/Badge/BadgePill'
import { TEXT_ENUM } from '@/styles/components/text'

export const NotificationCenterHeader = ({
	setIsOpen,
	filterStatus,
	setFilterStatus,
}: {
	setIsOpen: (isOpen: boolean) => void
	filterStatus: FilterStatus
	setFilterStatus: (status: FilterStatus) => void
}) => {
	const { feedClient, useFeedStore } = useKnockFeed()
	const { items } = useFeedStore()
	const unreadCount = items.filter((item) => !item.read_at).length

	const handleMarkAllAsRead = async () => {
		await feedClient.markAllAsRead()
	}

	return (
		<Flex
			position="sticky"
			top={0}
			w="100%"
			px={4}
			py={3}
			justifyContent="space-between"
			alignItems="center"
			bg="white"
			borderBottom={`1px solid ${COLORS.gray[4]}`}
			borderRadius="8px 8px 0 0"
			zIndex={9999}
		>
			<Flex direction="column" w="full" alignItems="center" gap={4}>
				<Flex w="full" justifyContent="space-between" gap={2}>
					<Text variant={TEXT_ENUM.semibold16}>Notifications</Text>
					<Flex p={1.5} cursor="pointer" onClick={() => setIsOpen(false)}>
						<GetIcon
							boxSize={4}
							icon={Icons.close}
							color={COLORS.background[5]}
						/>
					</Flex>
				</Flex>

				<Flex w="full" alignItems="center" justifyContent="space-between">
					<Flex alignItems="center" gap={2}>
						<Flex
							cursor="pointer"
							onClick={() => setFilterStatus(FilterStatus.Unread)}
						>
							<BadgePill
								type={
									filterStatus === FilterStatus.Unread
										? BadgePillType.info
										: BadgePillType.neutral
								}
								text={`Unread${unreadCount > 0 ? ` (${unreadCount})` : ''}`}
							/>
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => setFilterStatus(FilterStatus.All)}
						>
							<BadgePill
								type={
									filterStatus === FilterStatus.All
										? BadgePillType.info
										: BadgePillType.neutral
								}
								text="All notifications"
							/>
						</Flex>
					</Flex>
					{unreadCount > 0 && (
						<Flex
							alignItems="center"
							gap={1}
							color={COLORS.green[9]}
							cursor="pointer"
							onClick={handleMarkAllAsRead}
						>
							<Flex>
								<GetIcon strokeWidth={2.5} icon={Icons.check} />
								<GetIcon strokeWidth={2.5} ml={-2.5} icon={Icons.check} />
							</Flex>
							<Text variant={TEXT_ENUM.semibold14}>Mark all as read</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>
	)
}
