import './overrides.css'

import { Box, Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { useKnockFeed, useOutsideClick } from '@knocklabs/react'
import { ReactNode, useRef, useState } from 'react'

import { useAppSelector } from '@/app/hooks'
import { selectIssHomeSidebarExpanded } from '@/app/user/slice/selectors'
import {
	COLLAPSED_HOME_SIDEBAR_WIDTH_PX,
	EXPANDED_HOME_SIDEBAR_WIDTH_PX,
} from '@/common/constants/sidebar'

import { NotificationCenter } from './NotificationCenter'

type WithNotificationsProps = {
	children: (props: {
		isOpen: boolean
		setIsOpen: (isOpen: boolean) => void
		unreadCount: number
	}) => ReactNode
}

export const WithNotifications = ({ children }: WithNotificationsProps) => {
	const { useFeedStore } = useKnockFeed()
	const { items } = useFeedStore()
	const [isOpen, setIsOpen] = useState(false)
	const isHomeSidebarExpanded = useAppSelector(selectIssHomeSidebarExpanded)
	const ref = useRef(null)

	useOutsideClick({
		ref,
		fn: () => {
			setIsOpen(false)
		},
	})

	return (
		<Flex w="full" onClick={(e) => e.stopPropagation()}>
			<Flex
				ref={ref}
				bg="#000000"
				pos="fixed"
				top={0}
				right={0}
				w={
					!isOpen
						? '0!important'
						: `calc(100vw - ${isHomeSidebarExpanded ? EXPANDED_HOME_SIDEBAR_WIDTH_PX : COLLAPSED_HOME_SIDEBAR_WIDTH_PX})`
				}
				h="100vh"
				zIndex={10000}
				opacity={isOpen ? 0.12 : 0}
				transition="opacity 0.2s ease-out"
				onClick={() => {
					setIsOpen(false)
				}}
			/>
			<Menu isOpen={isOpen} placement="right-start">
				{children({
					isOpen,
					setIsOpen,
					unreadCount: items.filter((item) => !item.read_at).length,
				})}
				<MenuButton as={Box} />
				<MenuList
					p={0}
					w="420px"
					h="calc(100vh - 20px)"
					m="10px"
					pointerEvents="auto"
					zIndex={10001}
				>
					<NotificationCenter setIsOpen={setIsOpen} />
				</MenuList>
			</Menu>
		</Flex>
	)
}
