import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DateRange } from '@/app/scheduler/types'

export interface WorkflowLogsState {
	currentPage: number
	searchQuery?: string
	dateRange: DateRange | null
}

export const initialState: WorkflowLogsState = {
	currentPage: 1,
	searchQuery: '',
	dateRange: null,
}

export const workflowLogsSlice = createSlice({
	name: 'workflowLogs',
	initialState,
	reducers: {
		setCurrentPage: (state, { payload }: PayloadAction<number>) => {
			state.currentPage = payload
		},
		setSearchQuery: (state, { payload }: PayloadAction<string>) => {
			state.searchQuery = payload
			state.currentPage = 1
		},
		setDateRange: (state, { payload }: PayloadAction<DateRange | null>) => {
			state.dateRange = payload
			state.currentPage = 1
		},
	},
})

export const workflowLogsActions = workflowLogsSlice.actions
export const workflowLogsReducer = workflowLogsSlice.reducer
