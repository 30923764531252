import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	checkboxAnatomy.keys,
)

export const checkboxTheme = defineMultiStyleConfig({
	baseStyle: () => ({
		control: {
			bg: ColorTokens.white,
			borderRadius: '4px',

			border: '1px solid',
			borderColor: COLORS.gray[7],

			_indeterminate: {
				bg: COLORS.purple[9],
				borderColor: 'transparent',
				_hover: {
					bg: COLORS.purple[9],
				},
			},

			_checked: {
				bg: COLORS.purple[9],
				borderColor: 'transparent',
				_hover: {
					bg: COLORS.purple[9],
				},

				_focus: {
					boxShadow: 'none',
					outline: 'none',
				},
			},
		},
	}),
	defaultProps: {
		size: 'lg',
	},
})
